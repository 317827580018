import { differenceInDays, format, formatDistanceToNowStrict, isMatch, parse, sub } from 'date-fns';
import { formatDistanceToNowWithShortText } from '@/src/common/helpers/date-format-overrides.js';

export function parseDate(date) {
  if (!date) {
    return null;
  } else if (date instanceof Date) {
    return date;
  } else if (typeof date === 'string') {
    const dateTimeString = date.trim();

    if (isMatch(dateTimeString, 'yyyy-MM-dd')) {
      // Use locale time zone
      return parse(dateTimeString, 'yyyy-MM-dd', new Date());
    } else {
      const stringWithoutIsoChars = dateTimeString.replace('T', ' ').replace('Z', '');

      if (isMatch(stringWithoutIsoChars, 'yyyy-MM-dd HH:mm:ss.SSS')) {
        // Use UTC since we store dateTime objects in the DB using UTC
        const returnDate = new Date(dateTimeString);

        // make objects an exact local day
        returnDate.setHours(0);
        returnDate.setMinutes(0);
        returnDate.setSeconds(0);
        returnDate.setMilliseconds(0);

        return returnDate;
      } else {
        throw `Unsupported date format for date: '${dateTimeString}'`;
      }
    }
  } else {
    // Unsupported data type
    return null;
  }
}

export function parseDateTime(dateTime) {
  if (!dateTime) {
    return null;
  } else if (dateTime instanceof Date) {
    return dateTime;
  } else if (typeof dateTime === 'string') {
    const dateTimeString = dateTime.trim();

    if (isMatch(dateTimeString, 'yyyy-MM-dd')) {
      // Use locale time zone
      return parse(dateTimeString, 'yyyy-MM-dd', new Date());
    } else {
      const stringWithoutIsoChars = dateTimeString.replace('T', ' ').replace('Z', '');

      if (
        isMatch(stringWithoutIsoChars, 'yyyy-MM-dd HH:mm:ss.SSS') ||
        isMatch(stringWithoutIsoChars, 'yyyy-MM-dd HH:mm:ss')
      ) {
        // Use UTC since we store dateTime objects in the DB using UTC
        return new Date(dateTimeString);
      } else {
        throw `Unsupported date format for dateTime: '${dateTimeString}'`;
      }
    }
  } else {
    // Unsupported data type
    return null;
  }
}

export function formatDateToLongFormat(dateTime, { includeHoursAndMinutes } = {}) {
  if (!(dateTime instanceof Date)) return '';

  return format(dateTime, `EEE MMM d, yyyy${includeHoursAndMinutes ? ' h:mm a' : ''}`);
}

export function formatDateToUserDisplayFormat(dateTime, userDateDisplayFormat) {
  if (!(dateTime instanceof Date)) return '';

  // YYYY, and DD should be lowercase per unicode technical spec. Context: https://date-fns.org/v2.28.0/docs/format
  const dateFormat =
    userDateDisplayFormat?.replace('YYYY', 'yyyy')?.replace('DD', 'dd') || 'MMM dd, yyyy';

  return format(dateTime, dateFormat);
}

export function formatDateToRelativeUserDisplayFormat(
  dateTime,
  { userDateDisplayFormat, includeHoursAndMinutes, omitSameYear, wantsRelativeDates } = {}
) {
  const setDefault = function (obj, defaultValue) {
    return typeof obj === 'boolean' ? obj : defaultValue;
  };

  includeHoursAndMinutes = setDefault(includeHoursAndMinutes, false);
  omitSameYear = setDefault(omitSameYear, false);
  wantsRelativeDates = setDefault(wantsRelativeDates, true);

  if (!(dateTime instanceof Date)) return '';

  const now = new Date();
  const daysFromNow = differenceInDays(dateTime, now);

  if (wantsRelativeDates && Math.abs(daysFromNow) < 7) {
    return formatDistanceToNowWithShortText(dateTime, { addSuffix: true });
  } else if (omitSameYear && dateTime.getUTCFullYear() === now.getUTCFullYear()) {
    const dateFormat = includeHoursAndMinutes ? 'MMM dd h:mm a' : 'MMM dd';
    return format(dateTime, dateFormat);
  } else {
    // YYYY, and DD should be lowercase per unicode technical spec. Context: https://date-fns.org/v2.28.0/docs/format
    let dateFormat =
      userDateDisplayFormat?.replace('YYYY', 'yyyy')?.replace('DD', 'dd') || 'MMM dd, yyyy';
    dateFormat = includeHoursAndMinutes ? dateFormat + ' h:mm a' : dateFormat;
    return format(dateTime, dateFormat);
  }
}

export function formatSecondsInWords(seconds) {
  const nowDateTime = new Date();
  return formatDistanceToNowStrict(sub(nowDateTime, { seconds }));
}

export function isRailsDate(obj) {
  if (typeof obj !== 'string') {
    return false;
  }

  const isShortDate = isMatch(obj, 'yyyy-MM-dd');

  // Ex: "2022-11-01T01:38:09.229Z"
  // Removing the 'T' and 'Z' from the rails ISO string for the date-fns isMatch call
  // https://date-fns.org/v2.29.3/docs/isMatch
  const stringWithoutIsoChars = obj.replace('T', ' ').replace('Z', '');
  const isRailsIsoDate = isMatch(stringWithoutIsoChars, 'yyyy-MM-dd HH:mm:ss.SSS');

  return isShortDate || isRailsIsoDate;
}

export function formatTimestampToUserDisplayFormat(timestamp) {
  return formatDateToUserDisplayFormat(new Date(timestamp * 1000));
}
